$toolbar: 56px;
$toolbar-mobile: 42px;

html, body {
  background: #000;
  border: 0px;
  margin: 0px;
  padding: 0px;
  font-family: monospace;
}

.app {
  position: relative;

  .bodge {
    position: fixed;
    bottom: 0px;
    right: 0px;
    background: #000;
    padding: 5px;
    font-style: italic;

    a {
      color: #fff;
    }
  }

  .toolbar {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
    width: 100%;
    background: #000;
    height: $toolbar;

    @media(max-width: 800px) {
      height: $toolbar-mobile;
    }

    .logo {
      font-family: "corpulent-web-1","corpulent-web-2";
			font-size: 42px;
      line-height: 1;
      padding: 10px;

      @media(max-width: 800px) {
        font-size: 28px;
      }

      span {
        &:nth-of-type(1) {
          color: #78faff;
        }
        &:nth-of-type(2) {
          color: #6fe6eb;
        }
        &:nth-of-type(3) {
          color: #5ec3c7;
        }
        &:nth-of-type(4) {
          color: #489799;
        }
        &:nth-of-type(5) {
          color: #377274;
        }
      }
    }

    .toggle {
      position: absolute;
      right: 20px;
      top: 0px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media(max-width: 800px) {
        right: 5px;
      }

      button {
        border: 0;
        background: none;
        user-select: none;
        outline: none;
        transition: 500ms all;
        opacity: 1;
        cursor: pointer;
      }
      img {
        width: 32px;

        @media(max-width: 800px) {
          width: 22px;
        }

      }
    }
  }

  .colors {
    margin-top: $toolbar;

    @media(max-width: 800px) {
      margin-top: $toolbar-mobile;
    }

    .color {
      position: relative;
      cursor: pointer;
      overflow: hidden;

      .color-hex {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        transition: 500ms all;
        opacity: 0;
        font-size: 22px;
      }

      .color-image {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        display: none;
        transition: 500ms all;
      }

      .color-time {
        position: absolute;
        bottom: 5px;
        width: 100%;
        text-align: center;
        opacity: 0;
        z-index: 1;
        font-size: 10px;
      }

      &:hover {
        .color-image {
          display: block;
          animation: 500ms fadeIn forwards;
        }

        .color-hex {
          opacity: 1;
          transition: 500ms all;
        }

        .color-time {
          opacity: 0.5;
          transition: 500ms all;
        }
      }
    }
  }

  .colors-grid {
    .color {
      height: 20vh;
      width: 20vw;
      float: left;

      @media(max-width: 800px) {
        width: calc(50%);
        height: 16vh;
      }

      @media(min-width: 2000px) {
        width: 10vw;
        height: 10vh;
      }
    }
  }

  .colors-line {
    .color-image {
      background-image: none !important;
    }
    .color-image &:hover {
      display: none !important;
      animation: none !important;
      background-image: none !important;
    }

    .color-hex {
      position: relative !important;
    }

    .color-time {
      display: none;
    }
  }
}

.color-dark {
  color: #fff !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
